import * as React from 'react'
// import { styled } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
// import AccountCircle from '@mui/icons-material/AccountCircle'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
// import MenuIcon from '@mui/icons-material/Menu'
import IosShareIcon from '@mui/icons-material/IosShare'
import Button from '@mui/material/Button'

import { APPLICATION_NAME, APPLICATION_URL } from 'src/config-global'
import { dispatch, useSelector } from 'src/redux/store'
// import { doLogout } from 'src/features/auth/slices'
import AppLogo from 'src/components/app-logo'
import { PATH_PAGE } from 'src/routes/paths'
import { setPageContext } from 'src/redux/slices/app-slice'
import LiveChat from 'src/components/live-chat'
import { getAnchor } from 'src/components/live-chat/live-chat'
import * as Styled from './styles'

const drawerWidth = 240
// const navItems = ['Home', 'About', 'Contact']
const navItems = []

function AppHeader(props) {
  const { window } = props

  const { pageContext, agencyInfo } = useSelector(state => state.app)

  const [mobileOpen, setMobileOpen] = React.useState(false)
  // const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const shouldDisplayBackButton = location.pathname !== PATH_PAGE.general.root
  const shouldDisplayNavBar =
    location.pathname !== PATH_PAGE.care.thankYou &&
    location.pathname !== PATH_PAGE.caregiver.thankYou &&
    location.pathname !== PATH_PAGE.provider.thankYou

  const goBack = React.useCallback(() => {
    navigate(-1)
    dispatch(setPageContext())
  }, [navigate])

  // const handleMenu = event => {
  // setAnchorEl(event.currentTarget)
  // }

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  const handleShareButton = () => {
    if (navigator.share) {
      navigator
        .share({
          url: APPLICATION_URL,
        })
        .then(() => {
          console.log('Sharing successful')
        })
        .catch(() => {
          console.log('Sharing failed')
        })
    } else {
      console.log('Sorry! Your browser does not support Web Share API')
    }
  }

  // const handleLogout = React.useCallback(() => {
  //   dispatch(doLogout())
  //   setAnchorEl(null)
  // }, [])

  // React.useEffect(() => {
  //   if (agencyInfo) return

  //   setTimeout(() => {
  //     // Clear the comm100 chat
  //     const comm100Elements = document.querySelectorAll(
  //       '[id^="comm100-iframe"]'
  //     )
  //     if (!comm100Elements && comm100Elements.length <= 0) return
  //     comm100Elements.forEach(element => {
  //       if (element.parentNode) {
  //         element.remove()
  //       }
  //     })
  //   }, 100)
  // }, [agencyInfo])

  React.useEffect(() => {
    const anchorTag = getAnchor()
    if (!anchorTag) return
    if (agencyInfo) {
      anchorTag.classList.add('chat-link--button')
      return
    }
    anchorTag.classList.remove('chat-link--button')
  }, [agencyInfo])

  // React.useEffect(() => {
  //   if (agencyInfo) return

  //   setTimeout(() => {
  // if (com100ScriptRef) {
  //   document.head.removeChild(com100ScriptRef)
  // }
  // const scriptTags = document.head.querySelectorAll('script')
  // for (let i = 0; i < scriptTags.length; i++) {
  //   const script = scriptTags[i]
  //   if (
  //     script.src === 'https://vue.comm100.com/livechat.ashx?siteId=1000547'
  //   ) {
  //     script.parentNode.removeChild(script)
  //     break // Remove only the first matching script (optional)
  //   }
  // }
  //   }, 100)
  // }, [agencyInfo, com100ScriptRef])

  const handleDrawerToggle = () => {
    if (false) {
      setMobileOpen(prevState => !prevState)
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {APPLICATION_NAME}
      </Typography>
      <Divider />
      <List>
        {navItems.map(item => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      {shouldDisplayNavBar && (
        <>
          <AppBar
            component="nav"
            color="transparent"
            position="fixed"
            sx={{
              backgroundColor: '#212b36',
              minHeight: 56,
            }}
          >
            <Toolbar>
              <Box sx={{ width: '40px', height: '40px', mr: 2 }}>
                {shouldDisplayBackButton && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={goBack}
                  >
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                )}
              </Box>
              <Styled.StyledCenterLogo>
                <AppLogo
                  height={50}
                  width={230}
                  color="#fff"
                  backgroundSize="110%"
                />
              </Styled.StyledCenterLogo>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map(item => (
                  <Button key={item} sx={{ color: '#fff' }}>
                    {item}
                  </Button>
                ))}
              </Box>
              <Box flex={1} />
              <Box sx={{ width: '40px', height: '40px', ml: 2 }} />
              <Styled.StyledNavButtonContainer>
                <Box>
                  <Box sx={{ width: '29px', height: '29px' }} />
                  {pageContext?.cityZip?.zipCode &&
                    agencyInfo?.chat_js_id &&
                    agencyInfo?.agency_uuid && (
                      <>
                        <Typography sx={{ fontSize: '13px' }}>
                          Live Chat
                        </Typography>
                        <LiveChat
                          uuid={agencyInfo?.agency_uuid}
                          codePlan={agencyInfo?.chat_js_id}
                        />
                      </>
                    )}
                </Box>

                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleShareButton}
                  color="inherit"
                >
                  <Box>
                    <IosShareIcon sx={{ fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '13px' }}>Share</Typography>
                  </Box>
                </IconButton>
              </Styled.StyledNavButtonContainer>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </>
      )}
    </>
  )
}

export default AppHeader
