import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import AppFinalScreenImage from '../../assets/app_final_screen_image.png'
// import { isIOS } from 'src/utils/device-and-browser-detection'

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  paddingRight: theme.spacing(5),
  paddingLeft: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    width: theme.breakpoints.values.sm,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

export const MainAppContainer = styled(Box)(({ theme, props }) => ({
  // height: isIOS() ? 'calc(100vh - 84px)' : 'calc(100vh - 0px)',
  height: '100%',
  paddingTop: props?.showBgImg ? 0 : 70,
  background: props?.showBgImg
    ? `url(${AppFinalScreenImage}) #ffffff no-repeat center center`
    : 'none',
  backgroundSize: 'cover',
  boxSizing: 'border-box',
}))

export const StyledCenterLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  alignContent: 'stretch',
  flex: '100%',
}))

export const StyledNavButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 116,
  right: 2,
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))
