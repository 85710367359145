const ROOTS_CAREGIVER = '/care-giver'

export const CAREGIVER_PATHS = {
  root: ROOTS_CAREGIVER,
  benefits: 'benefits',
  testimonial: 'testimonial',
  cityZipSelection: 'city-zip-selection',
  questionAnswer: 'question-answer',
  experience: 'experience',
  shiftTiming: 'shift-timing',
  personalInfo: 'personal-info',
  bookAppointment: 'book-appointment',
  dateAndTime: 'date-and-time',
  availableTimeSlots: 'available-time-slots',
  appointmentDetails: 'appointment-details',
  thankYou: 'thank-you',
}
