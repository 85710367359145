import { _merge } from 'src/utils/app-dash-utility'
import { useMemo } from 'react'
// @mui
import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'

// system
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'
import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'

function isPwa() {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    displayMode =>
      window.matchMedia('(display-mode: ' + displayMode + ')').matches
  )
}

export default function ThemeProvider({ children }) {
  const baseOption = useMemo(
    () => ({
      palette: palette('dark'),
      shadows: shadows('dark'),
      customShadows: customShadows('dark'),
      typography,
      shape: { borderRadius: 8 },
      isPWA: isPwa(),
    }),
    []
  )

  const theme = createTheme(baseOption)

  theme.components = _merge(componentsOverrides(theme))

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
